.swal-title,
.swal2-title { font-size: 22px !important;color:rgba(0,0,0,.65);}
.swal-icon,
.swal2-icon {width: 60px; height: 60px;}
.swal-icon--warning__body {height: 30px !important;}
.swal2-icon--warning__body {height: 30px !important;}
.swal-button,
.swal2-button {padding: 7px 24px}
.swal-button:active,
.swal2-button:active {background-color: $theme-color-hover !important}
.swal-button--confirm:not([disabled]):hover {background-color: $theme-color-hover !important}
.swal2-button--confirm:not([disabled]):hover {background-color: $theme-color-hover !important}
.swal2-actions { width: 100%;justify-content: end; flex-direction: row-reverse;}

.swal2-confirm {margin-right: 20px!important;font-size: 14px!important;border: none!important; box-shadow: none!important; border-radius: 5px!important; font-weight: 600!important;height: 35px!important;padding: 7px 24px;background-color: $theme-color !important;}
.swal2-deny {font-size: 14px!important;border: none!important; box-shadow: none!important; border-radius: 5px!important; font-weight: 600!important;height: 35px!important;padding: 7px 24px;background-color: #efefef !important;color: #555 !important;}
.swal2-popup {margin-bottom : 12px}
.swal2-styled {padding : .25em 1.1em !important; min-width: 75px; font-size: 16px !important; font-weight: 500!important;}