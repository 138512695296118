.react-select-virtualized{ 
    svg {margin-top: -2px;}
    div {height:31px; min-height:31px;
        }}
.css-1wa3eu0-placeholder,  .css-1uccc91-singleValue {line-height: 32px;}

.flat-virtualized-item,
.grouped-virtualized-list-item {
  cursor: pointer;
  background-color: #fff;
  user-select: none;
  box-sizing: border-box;
  display: flex;
}
.css-1pahdxg-control {box-shadow: none !important; border-color:#80bdff !important}