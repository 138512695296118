@import url(https://fonts.googleapis.com/css?family=Raleway:300,700);

body {
  width:100%;
  height:100%;
  font-family: 'Raleway', sans-serif;
  font-weight:300;
  margin:0;
  padding:0;
}

img {
	max-width: 150px;
}

.box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
}

strong {
	font-size: 86px;
}

@media only screen and  (min-width: 0px) and (max-width: 600px ) {
	img {
		max-width: 75px;
		margin-bottom: 20px;
	}

	strong {
		font-size: 46px;
	}
}

