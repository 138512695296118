.arrow-order {font-size: 10px; margin-top: 4px; cursor: pointer;float:right; margin-left: 15px;}
.head-th  { border-right: 1px solid #fff;}
.pagination {margin-bottom: 0px !important;}
.pagination .active{background-color: $theme-color-hover !important}
.grid-option-300 {width: 300px;}
.width25{width: 25px;}
.gradeX {
    td {height: 10px !important;}
}
.table {
    tbody {border-top:1px solid #DEE2E6 !important}
}
.text-right {text-align: right;}
.btn.button-dropdown {width:39px !important; height: 29px !important;}
.card-grid-box { width: 100%; height:70vh;padding-left: 3px !important; }
.card-grid-box-top-menu { width: 100%; height:67vh !important;padding-left: 3px !important; }
.box-hover:hover{
    background-color: #f7f8f9;
    cursor: pointer;
    padding: 6px !important;
}
@media (max-height: 620px) {
    .card-grid-box { width: 100%; height:52vh;padding-left: 3px !important; }
    .card-grid-box-top-menu { width: 100%; height:49vh !important;;padding-left: 3px !important; }
}

@media (max-height: 768px) {
   .card-grid-box { width: 100%; height:57vh;padding-left: 3px !important; }
   .card-grid-box-top-menu { width: 100%; height:54vh !important;;padding-left: 3px !important; }
}

