
.chat-list {max-width: 300px; font-family: "Roboto", "Open Sans", Helvetica, sans-serif !important;};
.title-group {margin-left : 5px; margin-top : 15px}
.title-group > span {font-weight: bold;}
.chat-list-box {display: flex; cursor: pointer; padding: 5px 10px 5px 15px}
.chat-list-box:hover {background-color: $hover;transform: scale(1.05);transform: scale(1.05); color:$theme-color-hover }
.avatar-no-photo {border-radius: 50%; border: 1px solid $border-line-color; width: 40px; height: 34px; background-color: $theme-color-opaque; text-align: center;} 
.avatar-no-photo > span {line-height: 33px;font-weight: normal; font-size: 16px;color:#fff}
.avatar {border-radius: 50%; width: 34px; height: 34px; } 
.chat-box-info {width:100%}
.row-data {margin: 6px 0px 6px 6px; display:flex; justify-content: space-between;}
.container-messages { right: 234px !important; width: 100%;  bottom:0; position:absolute; height: auto; display: flex; flex-direction: row-reverse; transform: translate3d(230px, 0, 0); transition: transform .3s ease, z-index 0s linear .3s;}

.message-line{max-width: 350px;overflow-x: hidden;}
.box-message {color:$text-commom-color; margin-top: auto; min-width: 285px !important; max-width: 285px; height: 350px; border-top-left-radius: 5px; border-top-right-radius: 10px; margin-left: 10px; box-shadow: 0 1px 4px rgba(0, 0, 0, .3);margin-right: 10px; display: flex; flex-direction: column;}
.offsidebar-open .container-messages { transform: translate3d(0, 0, 0); transition: transform .3s ease;}
.chat-title{ cursor: pointer; height: 40px; box-shadow: 0 1px 4px -2px rgba(0, 0, 0, .3); display: flex;border-top-left-radius: 5px; border-top-right-radius: 10px; align-items: center;}
.chat-title > div:nth-child(1) {display: flex; flex-direction: column;  width: 50px;}
.chat-title > div:nth-child(1) > img {border-radius: 50%; height: 30px; width: 30px; margin-left: 10px}
.chat-title > div:nth-child(1) > span {margin-top: -9px; margin-left: 32px; display: flex;}
.chat-title > div:nth-child(2) {display: flex; flex-direction: column; width: 100%;}
.chat-title > div:nth-child(2) > span:first-child  {font-weight: bold;font-size: 14px;}
.chat-title > div:nth-child(2) > span:nth-child(2)  {font-size: 11px; margin-top: -2px;}
.chat-title > div:nth-child(3)  {display: flex; flex-direction: row; width: 50px;}
.chat-title > div:nth-child(3) > em {margin-left: 10px; cursor: pointer;}
.chat-option {bottom: 35px; position: static; height: 42px;width: 285px; border-top : 1px solid $border-line-color; padding: 5px;}
.chat-option > div:nth-child(2) {display : flex}
.chat-option > div:nth-child(2)  > input {border: 0!important; width: 250px;}
.chat-option > div:nth-child(2)  > em {cursor: pointer; margin: 4px 10px 0px 5px; font-size: 16px;}
.chat-deactive {height: 40px;margin-bottom: 0px;}
.chat-messages {display: flex; flex-direction: column;overflow-y: auto; height: 270px;scroll-behavior: smooth;}
.chat-deactive > .chat-option {visibility: hidden; display: none;}
.chat-deactive > .chat-messages {visibility: hidden; display: none}
.chat-message-me { margin: 6px; display: flex; justify-content: flex-end;}
.chat-message-he { margin: 6px; display: flex; align-items: center;}
.chat-span{padding : 5px 12px; border-radius: 20px; width: auto;  max-width: 220px;  }
.chat-me { background-color: $theme-color-opaque; color: #fff;  }
.chat-he { background-color: $border-line-color;  }
.chat-img-he {border-radius: 50%; width: 30px; height: 30px; margin-right: 10px;}
.avatar-no-photo-window {width: 30px; height: 30px; display: flex; align-items: center; justify-content: center; margin-left: 10px; background-color: $theme-color-opaque; border-radius: 50%; color: #fff;}
.avatar-no-photo-window>span {    font-size: 16px; line-height: 33px; font-weight: normal;} 
.chat-userstatus {margin-top: 15px; padding: 5px; display: flex;justify-content: center; margin-bottom: 0px; background-color: $select-open-hover; border-radius: 25px; width: 190px; margin-left: auto; margin-right: auto;}
.chat-select-userstatus {width: 100px !important; margin-left:10px; margin-bottom: 0px !important; border:0px; background-color: $select-open-hover;}
.chat-select-userstatus:focus { background-color: $select-open-hover;}

.shadow-success {box-shadow: 0 0 6px #28a745;}
.shadow-warning {box-shadow: 0 0 6px #ffc107;}
.shadow-danger {box-shadow: 0 0 6px #f05050;}
.shadow-mutted {box-shadow: 0 0 6px #6c757d;}

.context-menu {    opacity: .0; transition: opacity .5s;}
.show { opacity: 1;}
.box-context {padding:5px; cursor: pointer; font-size: 14px; display: flex; gap:5px; border-radius: 5px;
&:hover { background: #f7f8f9;}
}

.btn-toogle-custom {height: 12px; min-height: 12px !important;}
.btn-toogle-custom::after {font-size: 18px;color:#a3a7aa;}
.pad-btm {display: flex;}
.pad-btm span {position: absolute;margin-left: 45px;margin-top: 50px;}

.chat-plus {width: 42px; height: 42px; box-shadow: 0 1px 4px -2px rgba(0, 0, 0, 0.3); border:1px solid $border-line-color; border-top-left-radius: 5px; border-top-right-radius: 5px; margin-top: auto; display: flex; align-items: center; justify-content: center;
>em {
    font-size : 20px
}}

.show-over {opacity: 0;
            transition: opacity 0.5s ease;
    &:hover{opacity: 1;}
}
.chat-popup { width: 40px; height: 40px; border: 1px solid $border-line-color; border-top-left-radius: 5px; border-radius:50%; margin-top: auto;box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);}
.fade-in {
    animation: fadeIn ease 1s;
    -webkit-animation: fadeIn ease 1s;
    -moz-animation: fadeIn ease 1s;
    -o-animation: fadeIn ease 1s;
    -ms-animation: fadeIn ease 1s;
    }
    @keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
    }
    
    @-moz-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
    }
    
    @-webkit-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
    }
    
    @-o-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
    }
    
    @-ms-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
    }

 .div-alert-message {
     background-color: $theme-color;
 }   

.emoji-button {cursor:pointer; height: 33px; display: flex; justify-content: flex-end; align-items: center;
    div {
        padding: 5px;
        img {width: 25px;}
        &:hover { background: #f7f8f9;}  
    }    
}