
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;200;300;400;500;600;700;800;900&display=swap' );

$theme-color           : #2473e0; 
$theme-color-hover     : #053e49;
$theme-color-end       : #4d81ca;
$theme-color-opaque    : #86b3f3d5;
$theme-color-disabled  : #78b3be;
$border-line-color     : #EAEAEA;
$theme-medium-decrade  : #0B4C58;
$text-commom-color     : #515253;
$gray-light            : #e4eaec;
$select-open           : #f8f9fa;
$select-open-hover     : #f8f9fa;
$hover                 : rgba(33, 33, 33, 0.1);

$font-family           : 'Roboto','Open Sans', Helvetica, sans-serif;
$font-size             : 0.8rem;
$tab-font-size         : 0.8rem;
$font-h4               : 1.1rem;
$distance-elements     : 5px;
$navbar-height         : 55px;








