.background-info {
    .tooltip-inner {background-color: $theme-color !important;}
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
        border-top-color: $theme-color!important;
    }
}
  
.background-error {
    .tooltip-inner {background-color: #dc3545 !important;}
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
        border-top-color: #dc3545!important;
    }
}
  