b {font-weight: bold !important;}
.text-color-theme {color: $theme-color }
.opacity65 {opacity: 0.65;}
.modal-footer {padding:0.3rem 0.75rem!important; border-top: 1px solid #cacaca !important}
.modal-footer-bottom-0 {padding-bottom: 0px !important}
.modal-footer-bottom-10 {padding-bottom: 10px !important}
.adjust-height { height: 89%; padding: 18px 20px 5px 0px}
.adjust-height-settings { height: 94%; padding: 18px 20px 5px 0px}
.nav-item-permissions{min-width: 220px;width: 220px;}
.box-permissions{width: 230px; overflow: hidden auto; min-width: 230px}
.content-wrapper-no-overflow { overflow: hidden !important};
.default-carder {display:flex; width:100%; margin-top: 15px; border : 1px dashed $border-line-color; border-radius: 5px; padding: 5px}
.container-pdf-viewer{width: 100%; height: 600px;}
.color-button {color:#000 !important}
.PhoneInputInput {border:0px!important}
//.btn-secondary:not(:disabled):not(.disabled):active,
// .btn-secondary.dropdown-toggle {
//     background-color: #13653f!important;
// }
.img-default img {max-width: 1000px !important}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
   // background-color: #f5f5f5;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.rti--container {max-height: 115px !important; overflow: auto!important;}
.subcategory-section {
    border: 1px solid $border-line-color; 
    border-radius: 5px; 
    padding: 5px; 
    height: 200px; 
    overflow-y: scroll;
}
.modal-category {
    width: 100% !important;
}

.box-radio {display : flex; 
    margin-left :15px; 
    align-items : center;
    
    input {cursor:pointer}}

@media only screen and  (max-width: 1800px) and (max-height: 700px ) {
    .adjust-height { min-height: 81%; height: 81%;overflow-y:auto;}
}

@media only screen and  (max-width: 1150px) and (max-height: 700px ) {
    .adjust-height { min-height: 77%; height: 77%;overflow-y:auto;}
}