.box-shadow { 
    box-shadow : rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    border-radius : 5px;
    border : 1px solid #dee2e6;
    padding:10px
}
.box-import {
    min-width:160px;
    width:160px;
    padding:35px 10px;
    max-height:150px;
    cursor:pointer;
    margin-top:25px
}

.text-required {font-size:15px; margin-left:5px; font-weight:bold;position:absolute; color:#dc3545!important}
.css-186odqa-ItemButton {margin: 0 !important;}
.button-correios {min-height: 10px !important; height: 20px; width: 100px; padding: 0px !important; margin-left: 10px; margin-top: -5px;}
.box-mynumbers {border-radius:5px; padding:5px; display:flex; gap:15px;  max-height:170px; height:180px;overflow: hidden;max-width: 500px;min-width: 450px;}
@media screen and (max-width: 1448px) {
    .box-mynumbers {width:49%;}
}

@media screen and (max-width: 1075px) {
    .box-mynumbers {width:100%;}
}

@media screen and (max-width: 430px) {
    .box-mynumbers {max-height:fit-content; height:fit-content;}
}
