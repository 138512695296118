.emoji-55zap {max-width:20px; max-height:20px}
.box-chatbothistory {border-radius:5px; padding:5px; display:flex; gap:15px;  max-height:155px; height:155px;overflow: hidden;}
.box-chat {width:300px; cursor:pointer; display:flex;background-color:#fff;
    &:hover {
        background-color: #f7f8f9;
    }
}

.box-date-history {display:flex; gap:5px}
.box-chat-selected {
    background-color: #cacaca;
    i.fa-trash {color:#fff !important}
}
.target {background-color:#0d6efd !important;}
.source {background-color:#20c997 !important;}
.box-fast-message {padding:10px; cursor : pointer; width: 100%;border-bottom:1px dashed #cacaca;
    &:hover {
        background-color:#f7f8f9 !important
    }
}
.section-container-attendance {margin-left: 0px !important;}
.EmojiPickerReact {width: 100% !important;}
.content-box-chat { display:flex; flex-direction:row; height: 100%;width: 100% !important;top : 0; position: absolute;}
.box-conversation {flex-grow: 1; overflow-x: auto; height: 100%;background: #f7f8f9;}
.box-conversation-emoji {height:calc(65vh - 95px) !important}
// .chatbot-number  {width: 110px; overflow: hidden;white-space: nowrap; text-overflow: ellipsis;}
// .chatbot-fluxo  {max-width: 70px; overflow: hidden;white-space: nowrap; text-overflow: ellipsis;}
// .chatbot-title {width: 60px; overflow: hidden;white-space: nowrap; text-overflow: ellipsis;}
.slider-chat {
        position: fixed;
        height: 100%;
        background: #fff;
        top: 0;
        right: -20px;
        width: 400px;
        z-index: 200;
        box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
        transform: translateX(100%);
        transition: transform 0.3s ease-out;
        border-top-right-radius: 5px;
}
.trash-over {padding:5px;
    &:hover {
        i {
            color:red !important
        }
    }
}

.pinner-over {padding:5px;
    &:hover {
        i {
            color:green !important
        }
    }
}

.slider-chat.open-chat {
        transform: translateX(0);
 
}
.btn-transparent-chatbot.show {
    border-top: 0.4em solid !important;
    border-right: 0.4em solid transparent !important;
    border-left: 0.4em solid transparent !important;
    &:active {
        border : 0px !important;  
    }
}

.btn-transparent-chatbot::after {
    border-top: 0.4em solid !important;
    border-right: 0.4em solid transparent !important;
    border-left: 0.4em solid transparent !important;
}

.btn-transparent-chatbot:active {
    border : 0px !important;  
}
.chatbot-number  {width: 200px; overflow: hidden;white-space: nowrap; text-overflow: ellipsis;}
.chatbot-fluxo  {width: 100px; overflow: hidden;white-space: nowrap; text-overflow: ellipsis;}
.chatbot-title {width: 100px; overflow: hidden;white-space: nowrap; text-overflow: ellipsis;}
.audio-recorder{  box-shadow: none !important; width: auto; padding-right: 10px; cursor: pointer;
    i {
        border: none !important;
        padding: 0px !important;
    }

    &:hover {
        i {
            font-size: 24px !important;
        }
    }
}
.button-message { display:flex; align-items:center; padding:0px 10px; cursor:pointer; 
    &:hover {
        i {
            font-size: 24px !important;
        }
    }
} 

.button-message-circle { display:flex; align-items:center; padding:0px 10px; cursor:pointer;width: 50px; height: 50px; border-radius: 50%; border:1px solid #cacaca; justify-content: center;
    &:hover {
        background-color: #cacaca;
        i {
            font-size: 28px !important;
            
        }
    }
}

.button-new {
    display:flex; padding:5px; cursor:pointer;
    i {
        font-size: 20px;
        &:hover {
            font-size: 22px;
        }
    }
}

.box-leads {
    display: flex;
    flex-direction: column;
    gap:3px;
    height:87vh;
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom:55px
}

.box-lead {
    padding:5px;
    border-bottom:1px solid #f7f8f9;
    display:flex;
    gap:5px;
    border-radius: 5px;
    cursor:pointer;
    &:hover {
        background-color: rgba(33, 33, 33, 0.1);
    }
}

.box-image-visualization  {max-width:800px; max-height: 600px}
.box-image-list-visualization  {max-width:100px; max-height: 80px;}
.container-box-image {display:flex;align-items:center; justify-content:center}
//.container-box-image2 {height: 585px;} 
.sound-wave {border-radius: 30px;}

.box-webhook {font-size:14px; display:flex; gap:5px; padding:5px; border:1px dashed #cacaca; border-radius:5px; cursor:pointer;
    &:hover {
        background-color: #f7f8f9;
    }

}

@media screen and (max-width: 1448px) {
    .box-chatbothistory {width:49%;}
}

@media screen and (max-width: 1075px) {
    .box-chatbothistory {width:100%;}
}

@media screen and (max-width: 430px) {
    .box-chatbothistory {max-height:fit-content; height:fit-content;}
    .box-date-history {width:100%}
}


@media (max-height: 768px) {
    .content-box-cart-grid { height:70vh !important;}
 }

 @media screen and (min-height: 600px) {
    .content-box-cart-grid { height:70vh !important;}
    .box-image-list-visualization  {max-width:50px; max-height: 40px;}
 }


