a {text-decoration: none !important;}
table {--bs-table-striped-bg: transparent !important; 

}
.btn-info { color: #fff !important;}
.form-select{font-size: 12.8px !important;}

.badge-success {background-color: #28a745;}
.badge-primary {background-color: #007bff}
.badge-secondary {background-color: #6c757d}
.badge-danger {background-color: #dc3545}
.badge-warning { color: #212529 !important; background-color: #ffc107;}
.badge-info {background-color: #17a2b8}
.badge-light {color: #212529; background-color: #f8f9fa;}
.badge-dark {background-color: #343a40;}

.modal-body {overflow: hidden !important;}