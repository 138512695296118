.workflow-delete {border: 1px dashed #dc3545; padding:1px 4px; border-radius:3px; background:#fff}
.workflow-delete:hover{background-color: #dc3545; i {color:#fff !important}}

.workflow-click {border: 1px dashed #198754; padding:1px 4px; border-radius:3px; background:#fff}
.workflow-click:hover{background-color: #198754; i {color:#fff !important}}

.workflow-copy {border: 1px dashed #5d9cec; padding:1px 4px; border-radius:3px; background:#fff}
.workflow-copy:hover{background-color: #5d9cec; i {color:#fff !important}}

.workflow-parameters {
  overflow-y: auto;
  height: 280px;
  .parameters__item {
    display: flex;
    cursor: pointer;
    span {
      font-weight: bold;
      width: 160px;
    }

    margin-bottom: 5px;
  }
}

.button-node-option {
    border-radius:5px;
    height:100px;
    width:100px;
    display:flex; 
    align-items:center;
    justify-content:center;
    flex-direction:column;
    cursor: pointer;
    border:1px dashed #6f42c1;
    background:#6f42c11c;
    &:hover {
        background-color: #6e42c134;
      }
  }

.dndflow {
  flex-direction: column;
  display: flex;
  height: 100%;
  width: 100%;

  .sidebar {
    border-right: 1px solid #eee;
    padding: 15px 5px;
    font-size: 12px;
    background: #f7f8f9;
    overflow-y: auto;
    z-index: 1;
    scrollbar-width: thin !important;
    max-width: 100px;
    .dndnode {
      background-color: white;
      max-height: 60px;
      padding: 20px 4px;
      box-shadow: 0px 2px 5px #888888;
      border-radius: 5px;
      margin-bottom: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: 85px;
      cursor: grab;
      .input {
        border-color: #0041d0;
        height: 100%;
      }
      .output {
        border-color: #ff0072;
      }
    }
  }

  .reactflow-wrapper {
    flex-grow: 1;
    height: 100%;
    .react-flow__handle {
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: transparent;
      border: 1px solid #cacaca;
    }
  }

  .rfnode {
    border-radius: 5px;
    padding: 0px;
    max-width: 140px;
    min-width: 100px;

    .rfnode-title {
      width: 100%;
      font-size: 8px;
      padding: 0px 15px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;

      color: white;
      font-weight: bold;
      text-align: center;
    }

    .info-message {
      background-color: #dfdfdf;
      padding: 0px 10px;
      font-size: 10px;
      max-width: 200px;
      border-radius: 5px;
      padding: 8px;
      margin: 0px 5px;

      .message-container {
        background-color: #d9fdd3;
        padding: 5px;
        border-radius: 5px;
      }
      .custom-node__options {
        position: relative;
        width: 100%;
        .btn {
          width: 100%;
        }
        .react-flow__handle {
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background-color: transparent;
            border: 1px solid #cacaca;
        }
      }
    }

    .rfnode-footer {
      width: 100%;
      font-size: 9px;
      text-align: end;
    }
  }

  .rfnode-start {
    border: 0;
    .rfnode-title {
        margin-top: -2px;
        background-color: #0ac06c1c;
        border: thin dashed #0ac06c;
        color: #0ac06c;
    }
    .info-message {
      text-align: center;
      max-width: 90px !important;
      font-size: 8px;
    }
  }

  .rfnode-ia {
    border: 0;
    .rfnode-title {
        margin-top: -2px;
        background-color: #0d6efd1c;
        border: thin dashed #0d6efd;
        color: #0d6efd;
    }
    .info-message {
      text-align: center;
      max-width: 90px !important;
      font-size: 8px;
    }
  }

  .rfnode-message {
    border: 0;
    .rfnode-title {
        margin-top: -2px;
        background-color: #6f42c11c;
        border: thin dashed #6f42c1;
        color: #6f42c1;
    }

    .message-container {
      background-color: #d9fdd3;
      padding: 5px;
      border-radius: 5px;
    }

    .message-footer {
      width: 100%;
      font-size: 8px;
      text-align: end;
    }
  }

  .rfnode-whatsapp {
    border: 0;
    .rfnode-title {
        margin-top: -2px;
        background-color: #25d3661c;
        border: thin dashed #25d366;
        color: #25d366;
    }

    .message-container {
      background-color: #d9fdd3;
      padding: 5px;
      border-radius: 5px;
    }

    .message-footer {
      width: 100%;
      font-size: 8px;
      text-align: end;
    }
  }


  .rfnode-audio {
    border: 0;
    min-width: 150px;
    .rfnode-title {
        margin-top: -2px;
        background-color: #0d6efd1c;
        border: thin dashed #0d6efd;
        color: #0d6efd;
    }
    .info-message {
      min-width: 140px;
    }
    .message-container {
      background-color: #0d6efd1c;
      padding: 5px;
      border-radius: 5px;
      width: 100%;
    }

    .message-footer {
      width: 100%;
      font-size: 8px;
      text-align: end;
    }
  }
  .rfnode-file {
    max-width: 260px;
    border: 0;
    .rfnode-title {
        margin-top: -2px;
        background-color: #6c757d1c;
        border: thin dashed #6c757d;
        color: #6c757d
    }
    .info-message {
      min-width: 230px;
    }
  }

  .rfnode-question {
    border: 1px solid #0cb09b;
    .rfnode-title {
      background-color: #0cb09b;
    }
    .info-message {
      .question {
        border: 5px;
        background-color: #d9fdd3;
        padding: 2px;
        border-radius: 5px;
      }
    }
  }

  .rfnode-button {
    border: 0;
    max-width: 260px;
    .rfnode-title {
      margin-top: -2px;
      background-color: #fd7e141c;
      border: thin dashed #fd7e14;
      color: #fd7e14
    }
    .custom-node__options {
        position: relative;
        .react-flow__handle {
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background-color: transparent;
            border: 1px solid #cacaca;
            right: -20px;
        }
    }
  }

  .rfnode-email {
    border: 0;
    max-width: 260px;
    .rfnode-title {
        margin-top: -2px;
        background-color: #DB70931c;
        border: thin dashed #DB7093;
        color: #DB7093;
    }
    .custom-node__options {
        position: relative;
        .react-flow__handle {
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background-color: transparent;
            border: 1px solid #cacaca;
            right: -20px;
        }
    }

  }

  .rfnode-list {
    border: 0;
    max-width: 260px;
    .rfnode-title {
        margin-top: -2px;
        background-color: #f532e51c;
        border: thin dashed #f532e5;
        color: #f532e5;
    }
    .custom-node__options {
        position: relative;
        .react-flow__handle {
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background-color: transparent;
            border: 1px solid #cacaca;
            right: -20px;
        }
    }

  }

  .rfnode-contact {
    border: 0;
    max-width: 260px;
    .rfnode-title {
        margin-top: -2px;
        background-color: #20c9971c;
        border: thin dashed #20c997;
        color: #20c997;
    }
    .custom-node__options {
        position: relative;
        .react-flow__handle {
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background-color: transparent;
            border: 1px solid #cacaca;
            right: -20px;
        }
    }

  }

  .rfnode-webhook {
    border: 0;
    max-width: 260px;
    .rfnode-title {
        margin-top: -2px;
        background-color: #0dcaf01c;
        border: thin dashed #0dcaf0;
        color: #0dcaf0;
    }
    .custom-node__options {
        position: relative;
        .react-flow__handle {
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background-color: transparent;
            border: 1px solid #cacaca;
            right: -20px;
        }
    }

  }

  .rfnode-input {
    border: 0;
    max-width: 260px;
    .rfnode-title {
        margin-top: -2px;
        background-color: #2125291c;
        border: thin dashed #212529;
        color: #212529;
    }
    .custom-node__options {
        position: relative;
        .react-flow__handle {
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background-color: transparent;
            border: 1px solid #cacaca;
            right: -20px;
        }
    }

  }

  .rfnode-redirect {
    border: 0;
    max-width: 260px;
    .rfnode-title {
        margin-top: -2px;
        background-color: #d633841c;
        border: thin dashed #d63384;
        color: #d63384;
    }
    .custom-node__options {
        position: relative;
        .react-flow__handle {
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background-color: transparent;
            border: 1px solid #cacaca;
            right: -20px;
        }
    }

  }

  .rfnode-location {
    border: 10px;
    min-width: 100px;
    max-width: 200px;
    .rfnode-title {
      margin-top: -2px;
      background-color: #e207ff1c;
      border: thin dashed #e207ff;
      color: #e207ff;
    }
    .info-message {
      background-color: white;
      font-size: 8px;
      .info-seconds {
        width: 12px;
        display: block;
        // padding: 5px;
        background-color: rgb(189, 189, 189);
      }
    }
  }

  .rfnode-action {
    border: 10;
    min-width: 100px;
    .rfnode-title {
      margin-top: -2px;
      background-color: #ffc1071c;
      border: thin dashed #ffc107;
      color: #ffc107;
    }
    .info-message {
      background-color: white;
      font-size: 8px;
      .info-seconds {
        width: 12px;
        display: block;
        // padding: 5px;
        background-color: rgb(189, 189, 189);
      }
    }
  }

  .rfnode-trigger {
    border: 10;
    min-width: 100px;
    .rfnode-title {
      margin-top: -2px;
      background-color: #C0A2D01c;
      border: thin dashed #C0A2D0;
      color: #C0A2D0;
    }
    .info-message {
      background-color: white;
      font-size: 8px;
      .info-seconds {
        width: 12px;
        display: block;
        // padding: 5px;
        background-color: rgb(189, 189, 189);
      }
    }
  }

  .rfnode-bridge {
    border: 10;
    min-width: 100px;
    .rfnode-title {
      margin-top: -2px;
      background-color: #2F4F4F1c;
      border: thin dashed #2F4F4F;
      color: #2F4F4F;
    }
    .info-message {
      background-color: white;
      font-size: 8px;
      .info-seconds {
        width: 12px;
        display: block;
        // padding: 5px;
        background-color: rgb(189, 189, 189);
      }
    }
  }

  .rfnode-randomize {
    border: 0;
    max-width: 260px;
    .rfnode-title {
      margin-top: -2px;
      background-color: #01585F1c;
      border: thin dashed #01585F;
      color: #01585F
    }
    .custom-node__options {
        position: relative;
        .react-flow__handle {
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background-color: transparent;
            border: 1px solid #cacaca;
            right: -20px;
        }
    }
  }


  .rfnode-condition {
    border: 10;
    min-width: 140px;
    max-width: 140px;
    .rfnode-title {
      margin-top: -2px;
      background-color: #8391921c;
      border: thin dashed #839192;
      color: #839192;
    }
    .info-message {
      background-color: white;
      font-size: 8px;
      .info-seconds {
        width: 12px;
        display: block;
        // padding: 5px;
        background-color: rgb(189, 189, 189);
      }
    }
  }


  .rfnode-end {
    border: 0;
    .rfnode-title {
        margin-top: -2px;
        background-color: #dc35451c;
        border: thin dashed #dc3545;
        color: #dc3545;
    }

    .info-message {
      margin-top: 5px;
      max-width: 150px;
      text-align: center;
      .message-container {
        background-color: #d9fdd3;
        padding: 5px;
        border-radius: 5px;
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .dndflow {
    flex-direction: row;
    aside {
      width: 10%;
      max-width: 250px;
    }
  }
}

.button-audio {
    max-height: 16px; min-height: 16px!important;  height: 16px !important; width: 16px !important; max-width: 16px !important; min-width: 16px; padding:0px 0px; line-height: 0;
}
