.vertical-timeline-custom-line::before { background: $theme-color;}
.vertical-timeline-element-content {-webkit-box-shadow: 0 1px 0 $border-line-color  !important; box-shadow: 0 1px 0 $border-line-color !important;}
.icon-timeline {font-size: 25px; margin-left: 8px; margin-top: 8px;}
.header-title-box {justify-content: space-between; display: flex;}
.header-module-date {display: flex; 
    >div {padding:3px 10px; border-radius: 5px; margin-right: 5px;font-weight: bold;}
}
.header-module-box {display: flex; flex-grow: 1;
    >div {padding:3px 10px; border-radius: 5px; background-color: $border-line-color; margin-right: 5px;font-weight: bold;}
}
.header-module-like {display: flex; align-items: center;
    >i {margin-left: 15px; font-size: 22px; cursor: pointer;}
}
.box-body{margin-top: 15px;
    .box-body-title {font-size: 18px; font-weight: bold; color:$theme-color;}
    .box-body-text {display: flex; flex-direction: column;}
    .box-body-item {font-size: 14px; font-weight: bold; margin-top: 10px;}
    .box-body-subtitle {font-size: 16px; font-weight: bold; margin-top: 10px;}}